<template>
    <div class="dish-restaurant">
        <router-link v-if="userRestaurants.ids.length > 1"
            class="dish-restaurant__link dish-restaurant__link_back link link_red"
            ref="backLink"
            :to="{ name: 'DishTotal' }"
        >
            Вернуться к общему отчету по блюдам
        </router-link>
        <dish-restaurant-settings class="dish-restaurant__settings" />
        <spinner v-if="status === 'loading'" class="dish-restaurant__spinner" />
        <error-message v-else-if="status === 'error'" class="dish-restaurant__error-message">
            Не удалось загрузить данные отчета "Блюда".
        </error-message>
        <section v-else-if="status === 'success'" class="dish-restaurant__data">
            <h2 class="dish-total__data-heading sr-only">Данные отчета "Блюда ресторана"</h2>
            <dish-base-table v-if="modeId === 'base'"
                :columns="columns"
                :totalRow="baseReportRestaurant"
                :rows="reportItems"
            />
            <template v-if="modeId === 'compareOtherPeriod'">
                <p class="dish-restaurant__error" v-if="!compareDish">Выберите период сравнения.</p>
                <template v-else>
                    <dish-compare-table
                        :columns="columns"
                        :reportRow="compareReportRestaurant"
                        :compareRow="compareRestaurant"
                    />
                    <dish-compare-table
                        v-for="reportItem in reportItems"
                        :key="reportItem.id"
                        :columns="columns"
                        :reportRow="reportItem"
                        :compareRow="findCompareDishItem(compareItems, reportItem)"
                    />
                </template>
            </template>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText, dateText, dateTextLong, datetimeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import DishRestaurantSettings from "./dish-restaurant/DishRestaurantSettings";
    import DishBaseTable from "./common/DishBaseTable";
    import DishCompareTable from "./common/DishCompareTable";

    export default {
        name: "DishRestaurant",
        components: {
            DishRestaurantSettings,
            DishBaseTable,
            DishCompareTable,
        },
        props: {
            id: {
                type: String,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.dish.compareDaterange,
                modeId: state => state.dish.modeId,
                itemsId: state => state.dish.itemsId,
                sorting: state => state.dish.sorting,
                data: state => state.dish.data[state.dish.itemsId]
            }),
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredDishes() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportDish() {
                return this.data?.[daterangeKey(this.reportDaterange)];
            },
            reportRestaurant() {
                if (this.id === "total") {
                    return this.reportDish?.total;
                }

                return this.reportDish?.restaurants?.find(restaurant => restaurant.id === this.id);
            },
            baseReportRestaurant() {
                if (this.itemsId === "dishes") {
                    return {
                        ...this.reportRestaurant,
                        title: "Все блюда",
                        dish_group: "Все группы блюд"
                    };
                } else if (this.itemsId === "groups") {
                    return {
                        ...this.reportRestaurant,
                        title: "Все группы блюд",
                    };
                }
            },
            compareReportRestaurant() {
                return {
                    ...this.reportRestaurant,
                    caption: this.itemsId === "dishes" ? "Все блюда" : "Все группы блюд",
                    title: daterangeText(this.reportDaterange)
                };
            },
            reportItems() {
                return this.reportRestaurant?.items?.map(item => {
                    let props = {};
                    if (this.modeId === 'compareOtherPeriod') {
                        props.caption = item.title;

                        if (item.dish_group) {
                            props.caption += ` - ${item.dish_group}`;
                        }

                        props.title = daterangeText(this.reportDaterange);
                    }

                    return {
                        ...item,
                        ...props
                    };
                })?.sort((item1, item2) => {
                    let compare = ["title", "dish_group"].includes(this.sorting.field) ? compareStrings : compareNumbers;

                    return compare(
                        item1[this.sorting.field],
                        item2[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
            compareDish() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[daterangeKey(this.compareDaterange)];
                }
            },
            compareRestaurant() {
                if (this.id === "total") {
                    return {
                        ...this.compareDish?.total,
                        title: daterangeText(this.compareDaterange),
                    };
                }

                return {
                    ...this.compareDish?.restaurants?.find(restaurant => restaurant.id === this.id),
                    title: daterangeText(this.compareDaterange),
                };
            },
            compareItems() {
                return this.compareRestaurant?.items?.map(item => {
                    if (this.modeId === 'compareOtherPeriod') {
                        return {
                            ...item,
                            title: daterangeText(this.compareDaterange)
                        };
                    }

                    return item;
                });
            },
            columns() {
                let columns = [
                    {
                        id: "title",
                        title: (this.modeId === "base" && (this.itemsId === "dishes" ? "Блюдо" : "Группа блюд")) || "Период",
                        alignment: "left",
                    },
                    {
                        id: "amount",
                        title: "Количество",
                        alignment: "right",
                        filter: "intFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                    {
                        id: "sum",
                        title: "Сумма",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                    {
                        id: "discount",
                        title: "Сумма со скидкой",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                    {
                        id: "cost",
                        title: "Себестоимость",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "negative",
                        },
                    },
                ];

                if (this.modeId === 'base' && this.itemsId === "dishes") {
                    columns.splice(1, 0, {
                        id: "dish_group",
                        alignment: "left",
                        title: "Группа"
                    });
                }

                return columns;
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setDishSorting"
            ]),
            ...mapActions([
                "requestDishes"
            ]),
            findCompareDishItem(compareItems, reportItem) {
                let compareItem = compareItems?.find(({ id }) => id === reportItem.id);

                if (typeof compareItem === "undefined") {
                    return {
                        title: reportItem.title
                    }
                }

                return compareItem;
            },
            showReport() {
                this.status = "success";
            },
            loadDishes() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestDishes({
                    dateranges: daterangesWithoutData,
                    itemsId: this.itemsId
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            daterangeText,
            dateTextLong,
            dateText,
            datetimeText
        },
        created() {
            if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({ name: "Error401" });
            } else {
                if (this.id === "total") {
                    this.setTitle("Блюда всех ресторанов");
                } else {
                    let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                    if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                        this.setTitle(`${this.title} "${restaurantTitle}"`);
                    }
                }

                if (this.requiredDishes.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadDishes();
                }
            }
        },
        watch: {
            requiredDishes(requiredDishes) {
                if (!requiredDishes.every(Boolean)) {
                    this.loadDishes();
                }
            }
        }
    }
</script>

<style lang="scss">
    .dish-restaurant__link {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }

    .dish-restaurant__data {
        display: grid;
        grid-gap: 16px;
    }
    .dish-restaurant__box {
        padding: 16px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: $gray-line;
        }
        &::-webkit-scrollbar-track {
            border-width: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $text-gray-secondary;
        }
    }
    .dish-restaurant__table {
        width: 100%;
    }
    .dish-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .dish-restaurant__table-row {
        &_body {
            .dish-restaurant__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .dish-restaurant__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .dish-restaurant__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_text-small {
            font-size: 16px;
        }

        &_text-medium {
            font-size: 18px;
        }

        &_tar {
            text-align: right;
        }
    }
    .dish-restaurant__table-value {
        display: block;
        line-height: 1;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
    .dish-restaurant__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }
    .dish-restaurant__table-text {
        flex-grow: 1;
        vertical-align: middle;
    }
    .dish-detail__table-cell_tar {
        text-align: right;
    }
</style>
